@import url("https://fonts.googleapis.com/css?family=Lobster|Roboto|Staatliches|Bree+Serif|Teko");
@import "@angular/material/prebuilt-themes/indigo-pink.css";

body,
html {
  height: 100%;
  margin: 0;
}

//TODO: Find better way to do styling without !important
.toast-error {
  background-color: #ed4c67 !important;
}
.toast-container .ngx-toastr {
  box-shadow: none !important;
}
.toast-container .ngx-toastr:hover {
  box-shadow: none !important;
}

mat-dialog-container > :first-child {
  height: 100%;
  display: flex;
  flex-flow: column;

  > :first-child {
    flex: 0 0 auto;
  }

  > :nth-child(2) {
    flex: 1 0 auto;
  }

  > :nth-child(3) {
    flex: 0 0 auto;
  }
}

app-main-game {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
